import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import NavItem from 'react-bootstrap/esm/NavItem';
import './navmenu.css';

const updateNavMenu = (selected) => {
    let navEle = document.getElementById("myNavBar")

    for (var i = 0; i < navEle.querySelectorAll('.nav-link').length; i++) {
        if (navEle.querySelectorAll('.nav-link')[i].className === 'nav-link active') {
            var activeTab = navEle.querySelectorAll('.nav-link')[i]
            activeTab.className = 'nav-link'
            document.getElementById(selected).className = "nav-link active"
            break
        }
    }
}

const NavigationMenu = () => {
    return (
        <Navbar expand="lg">
            <Container id="myNavBar" fluid>
                <Navbar.Brand id="navbarbrand" href="#home"
                    onClick={() => updateNavMenu("home")}
                >
                    <img
                        alt=""
                        // src="https://cdn.pixabay.com/photo/2018/02/27/01/55/gene-icon-3184523__480.png"
                        src="https://cdn.pixabay.com/photo/2018/02/27/02/01/gene-icon-3184531_1280.png"
                        width="40"
                        height="40"
                        className="d-inline-block align-middle"
                    />{' '}
                Trish Whetzel
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto"
                        onSelect={(selectedKey) => updateNavMenu(selectedKey)}
                    >
                        {/* <Nav.Item>
                            <Nav.Link id="home" href="#home"
                                eventKey="home"
                            >Home</Nav.Link>
                        </Nav.Item>
                        <NavItem>
                            <Nav.Link id="projects" href="#projects"
                                eventKey="projects"
                            >Projects</Nav.Link>
                        </NavItem>
                        <NavItem>
                            <Nav.Link id="about" href="#about"
                                eventKey="about"
                            > About</Nav.Link>
                        </NavItem> */}

                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                    </Nav>
                    {/* <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                </Form> */}
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default NavigationMenu;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './aboutme.css';


const AboutMe = () => {
    return (
        <Container fluid style={{ marginBottom: 12 }}>
            <Row className="aboutMe">
                <Col />
                <Col xs={8}>
                    <h1 style={{ fontWeight: 600 }}>
                        Hi, I'm Trish.
                    </h1>
                    <p>
                        I am a Full Stack software developer and build on web applications in the health care and life sciences.
                        I learned programming to analyze genome sequence data during my PhD and have worked for 10+ years
                        building data warehouses used by researchers worldwide. I have experience working
                        with a number of web development platforms as well as experience with CI/CD and AWS and Google Cloud Platform.
                    </p>
                </Col>
                <Col />
            </Row>
        </Container>
    )
}

export default AboutMe;
import React from 'react';
import './hero.css';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap/';

const styles = {
    customButton: {
        backgroundColor: '#0B0C10',
        borderColor: '#45A293',
        color: '#45A293',
        borderRadius: '100px'
    },
    header_text: {
        fontFamily: 'Arial, Helvetica, sansSerif',
        color: 'red'
    }
};

const Hero = () => {
    return (
        <Jumbotron fluid >
            <div>
                <h1>
                    Full stack web developer
                </h1>
                {/* <p style={styles.header_text}> */}
                <p>
                    I build life science data warehouses used by scientists worldwide.
                </p>
            </div>
        </Jumbotron>
    )
}

export default Hero;
import React from 'react';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import './footer.css';

const Footer = () => {
    return (
        <Container className="footerSection" fluid>
            <Row className="footerRow">
                <Col>
                    <Row>
                        <Col>
                            <img
                                alt=""
                                src="twhetzel_profile.jpg"
                                width="200"
                                height="200"
                                className="d-inline-block align-middle"
                            />{' '}
                        </Col>
                        <Col>I am a Full stack web developer with 10 years experience
                        working in academia and industry.</Col>
                    </Row>
                </Col>
                <Col xs={1} />
                <Col>
                    <Row>
                        <div>
                            <dt className="twitter">
                                <NavLink href="https://twitter.com/trishwhetzel" target="_blank">
                                    Twitter
                                </NavLink>
                            </dt>
                            <dd>Follow me for information on web development, cloud
                                computing, and bioinformatics.</dd>
                        </div>
                    </Row>
                    <Row>
                        <div>
                            <dt className="github">
                                <NavLink href="https://github.com/twhetzel/" target="_blank">GitHub</NavLink>
                            </dt>
                            <dd>Follow me to see my latest projects.</dd>
                        </div>
                    </Row>
                    <Row>
                        <div>
                            <dt className="linkedin">
                                <NavLink href="https://www.linkedin.com/in/trishwhetzel/" target="_blank">LinkedIn</NavLink>
                            </dt>
                            <dd>Let's connect on LinkedIn</dd>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container >
    )
}

export default Footer;
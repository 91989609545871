// import 'bootstrap/dist/css/bootstrap.min.css'; // Import in index.html from CDN
import { Fragment } from 'react';
import './App.css';
import NavigationMenu from './NavigationMenu';
import Main from './Main';
import { Container, Row } from 'react-bootstrap';

function App() {
  return (
    <Fragment>
      <NavigationMenu />
      <Main />
    </Fragment>
  );
}

export default App;

import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';
import Hero from '../Hero';
import FeaturedProjects from '../FeaturedProjects';
import AboutMe from '../AboutMe';
import Footer from '../Footer';

const Main = () => {
    return (
        <Fragment>
            <Hero />
            <AboutMe />
            <FeaturedProjects />
            <Footer />
        </Fragment>
    );
}

export default Main;
import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './featured_projects.css';

const FeaturedProjects = () => {
    return (
        <Container className="featuredProjectsContainer">
            <Row className="headerRow">
                <h1>Featured Projects</h1>
            </Row>
            <Row className="projectRow">
                <Col xs={5}>
                    <Card style={{ border: '0', backgroundColor: '#E7F7F9' }}>
                        <Card.Body style={{ margin: 12, textAlign: 'center' }}>
                            <Card.Title>GWAS Catalog</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ border: '0' }}>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted">Project Description</Card.Subtitle>
                            <Card.Text>
                                The GWAS Catalog is a catalog of human genome-wide association studies.
                            </Card.Text>
                            <Card.Text>
                                I worked on this project from 2017-2020. During
                                this time I implemented a new frontend, designed and
                                built a Solr based search, and enhanced the backend functionality.
                                I also wrote a pipeline to extract COVID-19 related
                                GWAS data for inclusion in the EMBL-EBI COVID-19 Data Portal.
                            </Card.Text>
                            <Card.Link href="https://www.ebi.ac.uk/gwas/" target="_blank"> >> View Project</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="gwas_catalog_home.png" />
                    </Card>
                </Col>
            </Row>
            <Row className="spacerRow" />
            <Row className="projectRow">
                <Col xs={5}>
                    <Card style={{ border: '0', backgroundColor: '#007C82' }}>
                        <Card.Body style={{ margin: 12, textAlign: 'center' }}>
                            <Card.Title>BioSamples</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ border: '0' }}>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted">Project Description</Card.Subtitle>
                            <Card.Text>
                                BioSamples is a database of metadata about biological
                                samples used in research and development by academia and industry.
                            </Card.Text>
                            <Card.Text>
                                I worked on this project in 2017 for 6 months providing
                                maternity coverage and built an application to harmonize metadata
                                across all samples using Natural Language Processing.
                            </Card.Text>
                            <Card.Link href="https://www.ebi.ac.uk/biosamples/" target="_blank">>> View Project</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="biosamples_home.png" />
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default FeaturedProjects;